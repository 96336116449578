<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <v-row align="center" justify="center">
          <h3 class="mr-3 text--bold my-5"> اختيار الصف والشعبة </h3>
        </v-row>
        <v-form v-model="isFormValidAdd">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-autocomplete v-model="data.chooseClass" :items="class_select" :loading="classLoading" :item-text="
                (item) => item.class_name + ' - ' + item.leader
              " :rules="addRules.classRules" clearable label="الصف والشعبة" solo return-object></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-btn depressed color="primary" :disabled="!isFormValidAdd" @click="goToStudentPage"> عرض </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import api from '@/api/api'
export default {
  data() {
    return {
      isFormValidAdd: false,

      class_select: [],

      leader_select: [],

      leader_select_filter: [],

      data: {
        chooseClass: null,
        chooseLeader: null,
      },

      classLoading: false,

      addRules: {
        classRules: [value => !!value || 'يجب اختيار الصف'],
        leaderRules: [value => !!value || 'يجب اختيار الشعبة'],
      },
      selected_class_school: {},
    }
  },
  created() {
    this.getClassSchool()
  },
  methods: {
    goToStudentPage() {
      this.$router.push(
        `exams/exam/${this.data.chooseClass._id}/${this.data.chooseClass.class_name}/${this.data.chooseClass.leader}`,
      )
    },
    async getClassAndSchoolName(event) {
      this.selected_class_school = await this.selectClassAndSchoolNameFromId(event)
    },

    selectClassAndSchoolNameFromId(class_school_id) {
      const { leader_select } = this
      // eslint-disable-next-line no-restricted-syntax
      for (const key in leader_select) {
        if (leader_select[key].class_school_id === class_school_id) {
          return leader_select[key]
        }
      }
    },

    async getClassSchool() {
      this.classLoading = true

      const response = await api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false

        this.class_select = response.data.results
        // this.leader_select = response.data.results
      }
    },

    FilteredLeaderArray() {
      const { chooseClass } = this.data
      console.log('chooseClass', chooseClass)
      const leader = this.leader_select
      this.leader_select_filter = []
      if (chooseClass) {
        for (let i = 0; i < leader.length; i++) {
          if (leader[i].class_school_forclass == chooseClass) {
            this.leader_select_filter.push(leader[i])
          }
        }
      } else {
        this.leader_select_filter = []
      }
    },
  },
}
</script>
